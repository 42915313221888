import * as React from 'react';

import Sticky from '../components/sticky';

import discordCompany from '../images/discord_company.png';

const Wrapper = ({ children }) => (
  <div style={{ background: 'fuchsia', padding: '24px' }}>
    <h3>Hello</h3>
    {children}
  </div>
);

export default () => {
  return (
    <div style={{ height: '2000px' }}>
      <div style={{ background: 'pink', marginTop: '100vh' }}>As above</div>
      <div style={{ background: 'orange', textAlign: 'center' }}>
        <Sticky height="279px" width="400px" wrapper={Wrapper} style={{ margin: '0 auto' }}>
          <img alt="dingo butts" src={discordCompany} height="1315.33" style={{ display: 'block' }} />
        </Sticky>
      </div>
      <div style={{ background: 'pink' }}>So below</div>
    </div>
  );
};
